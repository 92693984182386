import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueMask from "v-mask";

import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

Vue.use(VueMask);

Vue.use(Vuetify);

export default new Vuetify({});
