import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Order from "@/views/Order";
import Factor from "@/views/Factor";
import Test from "@/views/Test";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/test",
        name: "Test",
        component: Test,
    },
    {
        path: "/store/:storeId/table/:tableId",
        name: "Order",
        component: Order,
    },
    {
        path: "/store/:storeId/table/:tableId/order/:orderId/success",
        name: "Factor",
        component: Factor,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
