<template>
  <v-row class="mx-1 py-0 my-0">
    <v-col class="col-9 text-left py-0 my-0">{{ count }} x {{ food.name }}</v-col>
    <v-col class="col-3 text-right py-0 my-0">$ {{ food.totalPrice }}</v-col>
  </v-row>
</template>

<script>
export default {
  name: "ShoppingCardItemSummary",
  props: {
    food: Object,
    count: Number,
  },
};
</script>
