<template>
  <v-row>
    <v-col cols="12">
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Examples",
  props: {},
  data() {
    return {}
  },
  methods: {

  },
};
</script>