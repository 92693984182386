<template>
  <v-card class="mx-auth mt-5" outlined>

    <v-row>
      <v-col cols="12" md="3" class="text-center">
        <v-img height="164" class="grey lighten-3" :lazy-src="imageUrl(food.images[0])"
               :src="imageUrl(food.images[0])"></v-img>
      </v-col>
      <v-col cols="12" md="9">
        <p class="px-5 pt-sm-5 text-h6 mb-1 text-left">{{ food.name }}</p>

        <p class="px-5 my-4 text-left">
          <span
              class="text-subtitle-2  grey--text"
              v-for="(content, contentIndex) in food.contents"
              :key="content"
          >{{ content }}
            <small class="mr-1" v-if="contentIndex !== Object.keys(food.contents).length - 1"> . </small>
          </span>
        </p>

        <v-row>

          <v-col cols="12" md="4" class="px-3 text-left">
            <span class="mx-5 pt-1 pb-1 pr-5 pl-2  text-center success lighten-1  rounded-pill white--text ">
              <v-icon class="" size="20" dark>mdi-currency-usd</v-icon>
              <small class="font-weight-bold" style="font-size: 1em">{{ food.price }}</small>
            </span>
          </v-col>

          <v-col cols="12" md="8" class="text-right">
            <v-btn
                rounded
                small
                outlined
                color="grey"
                dark
                class="mb-5"
                elevation="0"
                @click="decCount()"
            >-
            </v-btn>

            <v-chip
                :color="count > 0 ? 'error' : 'white'"
                class="mx-2 py-2 font-weight-bold   mb-5"
            >{{ count }}
            </v-chip>

            <v-btn

                rounded
                small
                class="font-weight-bold mr-5 mb-5"
                color="black"
                dark
                elevation="0"
                @click="incCount"
            >+
            </v-btn>
          </v-col>

        </v-row>

      </v-col>


    </v-row>

  </v-card>
</template>

<script>
export default {
  name: "CardItem",
  methods: {
    imageUrl(imageId) {
      return process.env.VUE_APP_URL_BASE + "/files/" + imageId + "";
    },
    incCount() {
      this.$emit("incCount", this.food.id);
    },
    decCount() {
      this.$emit("decCount", this.food.id);
    },
  },
  props: {
    food: Object,
    count: Number,
  },
};
</script>

<style scoped></style>
