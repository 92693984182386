<template>
  <v-card class="my-3" elevation="0" outlined>
    <v-snackbar
        multi-line
        v-model="snackbar.show"
        timeout="3200"
        :color="snackbar.color"
    >
      <b> {{ snackbar.msg }}</b>
      {{ snackbar.details }}
    </v-snackbar>

    <v-card-title>Payment Info</v-card-title>
    <v-card-text>
      <form method="post" id="payment-form">
        <v-col cols="12">
          <div id="card-number" class="field card-number"></div>
          <div
              class="red--text text-caption"
              id="card-number-errors"
              role="alert"
          ></div>
        </v-col>

        <v-col cols="12">
          <div id="card-date" class="field third-width"></div>
          <div
              class="red--text text-caption"
              id="card-date-errors"
              role="alert"
          ></div>
        </v-col>

        <v-col cols="12">
          <div id="card-cvv" class="field third-width"></div>
          <div
              class="red--text text-caption"
              id="card-cvv-errors"
              role="alert"
          ></div>
        </v-col>

        <v-col cols="12">
          <div id="card-postal-code" class="field third-width"></div>
          <div
              class="red--text text-caption"
              id="card-postal-code-errors"
              role="alert"
          ></div>
        </v-col>

        <div id="card-response" role="alert"></div>

        <v-btn
            block
            class="px-5 mb-4"
            color="green mt-5"
            dark
            elevation="0"
            :loading="submitAndPayLoading"
            type="submit"
            form="payment-form"
        >Submit & Pay
        </v-btn>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "CloverPaymentBox",
  props: ["order", "note"],
  data() {
    return {
      token: "",
      testCP: "hi",
      submitAndPayLoading: false,
      snackbar: {
        show: false,
        color: "error",
        msg: "",
        details: "",
      },
    };
  },
  methods: {
    submitAndPay() {
      let cp = this;
      cp.submitAndPayLoading = true;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/submit`;
      axios
          .post(url, {
            note: cp.note,
            token: cp.token,
          })
          .then((response) => {
            if (response.status === 200) {
              cp.showSuccess("Order Placed!", "");

              router.push({
                name: "Factor",
                params: {
                  storeId: cp.storeId,
                  tableId: cp.tableId,
                  orderId: cp.order.id,
                },
              });
            } else {
              cp.showNetworkError(response);
            }
          })
          .catch((error) => {
            cp.showNetworkError(error);
          })
          .finally(() => {
            cp.submitAndPayLoading = false;
          });
    },
    showError(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "error";
    },

    showSuccess(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "success";
    },

    showNetworkError(error) {
      if (error.response) {
        if (error.response.status === 400) {
          this.showError("Oops!", error.response.data["msg"]);
        } else {
          this.showError("Oops!", error.message);
        }
      } else {
        this.showError("Oops!", error.message);
      }
    },
  },
  mounted() {
    let cp = this;
    //test
    // let PKAMSKEY = "05302a245e90235327f4b7e8a77b8ea4";
    //production
    // let PKAMSKEY = "7008d62964698d88ebeac131116063cb";
    let PKAMSKEY = "4a39599d0ce4b82be01da24d4954ecf9";

    /* global Clover */
    const clover = new Clover(PKAMSKEY);
    const elements = clover.elements();

    const styles = {
      "card-number input": {
        "font-size": "12px",
        border: "1px gray dotted",
        padding: "18px 20px",
        "background-color": "#f3f3f3",
        "border-radius": "40px",
      },
      "card-date input": {
        "font-size": "12px",
        border: "1px gray dotted",
        padding: "18px 20px",
        "background-color": "#f3f3f3",
        "border-radius": "40px",
      },
      "card-cvv input": {
        "font-size": "12px",
        border: "1px gray dotted",
        padding: "18px 20px",
        "background-color": "#f3f3f3",
        "border-radius": "40px",
      },
      "card-postal-code input": {
        "font-size": "12px",
        border: "1px gray dotted",
        padding: "18px 20px",
        "background-color": "#f3f3f3",
        "border-radius": "40px",
      },
    };

    const cardNumber = elements.create("CARD_NUMBER", styles);
    const cardDate = elements.create("CARD_DATE", styles);
    const cardCvv = elements.create("CARD_CVV", styles);
    const cardPostalCode = elements.create("CARD_POSTAL_CODE", styles);

    cardNumber.mount("#card-number");
    cardDate.mount("#card-date");
    cardCvv.mount("#card-cvv");
    cardPostalCode.mount("#card-postal-code");

    // eslint-disable-next-line no-unused-vars
    const cardResponse = document.getElementById("card-response");

    const displayCardNumberError = document.getElementById(
        "card-number-errors"
    );
    const displayCardDateError = document.getElementById("card-date-errors");
    const displayCardCvvError = document.getElementById("card-cvv-errors");
    const displayCardPostalCodeError = document.getElementById(
        "card-postal-code-errors"
    );

    cardNumber.addEventListener("change", function (event) {
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || "";
    });

    cardNumber.addEventListener("blur", function (event) {
      displayCardNumberError.innerHTML = event.CARD_NUMBER.error || "";
    });

    cardDate.addEventListener("change", function (event) {
      displayCardDateError.innerHTML = event.CARD_DATE.error || "";
    });

    cardDate.addEventListener("blur", function (event) {
      displayCardDateError.innerHTML = event.CARD_DATE.error || "";
    });

    cardCvv.addEventListener("change", function (event) {
      displayCardCvvError.innerHTML = event.CARD_CVV.error || "";
    });

    cardCvv.addEventListener("blur", function (event) {
      displayCardCvvError.innerHTML = event.CARD_CVV.error || "";
    });

    cardPostalCode.addEventListener("change", function (event) {
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || "";
    });

    cardPostalCode.addEventListener("blur", function (event) {
      displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || "";
    });

    const form = document.getElementById("payment-form");
    form.addEventListener("submit", function (event) {
      event.preventDefault();

      cp.submitAndPayLoading = true;
      clover
          .createToken()
          .then(async function (result) {
            if (result.errors) {
              cp.submitAndPayLoading = false;
              Object.values(result.errors).forEach(function (value) {
                cp.showError(value);
              });
            } else {
              cp.token = result.token;
              await cp.submitAndPay();
            }
          })
          .catch(function (data) {
            cp.submitAndPayLoading = false;
            cp.showError(data);
          });
    });
  },
};
</script>

<style scoped>
.field {
  max-height: 80px;
  height: 60px;
}
</style>
