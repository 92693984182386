<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer padless color="grey lighten-3">
      <v-col
          class="text-center"
          cols="12"
      >
        Powered by <strong><a href="https://vitalize.dev" target="_blank">Vitalize.dev</a></strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
