<template>
  <v-container fill-height>
    <v-row
        align="center"
        justify="center"
    >
      <v-col cols="12" class="text-center">
        <p class="text-h4 my-7 font-weight-bold">Menu is up!</p>
        <v-icon size="128" class="blue-grey--text">mdi-rocket</v-icon>
        <p class="text-h6 grey--text my-6">Scan a QR-Code to start</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Home",
  data: () => ({}),
};
</script>
