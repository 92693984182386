<template>
  <v-container fill-height>
    <v-btn
        v-show="!isIntersecting && order.items.length > 0"
        @click="
        $vuetify.goTo('#checkout');
        glowCheckout();
      "
        color="pink"
        elevation="2"
        bottom
        left
        fixed
        fab
        icon
    >
      <v-icon>mdi-basket</v-icon>
    </v-btn>

    <v-dialog v-model="call.show" max-width="300px">
      <v-card>
        <v-card-title>Call For Server</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
              class="mt-3"
              v-model="call.note"
              auto-grow
              outlined
              label="Call Note (optional)"
              append-icon="mdi-pen"
              rows="5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="mb-3"
              :loading="call.loading"
              :disabled="call.disable"
              block
              color="green"
              dark
              @click="callForServer"
          >
            <v-icon class="mr-5">mdi-bell</v-icon>
            Call Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        multi-line
        v-model="snackbar.show"
        timeout="3200"
        :color="snackbar.color"
    >
      <b> {{ snackbar.msg }}</b>
      {{ snackbar.details }}
    </v-snackbar>

    <v-row
        v-if="creatingOrder || menu.length <= 0"
        align="center"
        justify="center"
    >
      <v-col cols="12" class="text-center">
        <v-progress-circular
            :size="70"
            :width="7"
            color="error"
            indeterminate
        ></v-progress-circular>
        <br/>
        <p class="text-h6 my-7">Create New Order..</p>
      </v-col>
    </v-row>

    <v-row v-if="!creatingOrder && menu.length > 0">
      <v-col cols="12" class="mt-3">
        <v-btn
            :loading="call.loading"
            :disabled="call.disable"
            x-large
            block
            color="green"
            dark
            @click="call.show = true"
        >
          <v-icon class="mr-5">mdi-bell</v-icon>
          Call For Server
        </v-btn>
      </v-col>


      <v-col>
        <v-row>

          <v-col cols="12" class="text-center">
            <v-img height="164" contain class="black" lazy-src="@/assets/fklogo.png"
                   src="@/assets/fklogo.png"></v-img>
          </v-col>

          <v-col cols="6" class="align-center">
            <h1 class="text-left pl-5">
              {{ order.storeName }}<small class="grey--text pr-2" style="font-size: 0.8em"> Menu</small>
            </h1></v-col>
          <v-col cols="6" class="align-center text-right">
            <v-row align="center" class="pt-5 pr-10" justify="end">
              <v-badge
                  v-if="order.items.length > 0"
                  class="font-weight-bold"
                  :content="order.items.length"
                  color="error"
                  overlap
              >
                <v-btn
                    @click="
                    $vuetify.goTo('#checkout');
                    glowCheckout();
                  "
                    icon
                >
                  <v-icon x-large>mdi-basket</v-icon>
                </v-btn>
              </v-badge>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 text-left">
            <p class="pl-5">
              <v-icon class="mr-5">mdi-food-fork-drink</v-icon>
              <span class="text-h6">{{ order.tableName }}</span>
            </p>
            <!--            <p class="pl-5">-->
            <!--              <v-icon class="mr-5">mdi-tag</v-icon>-->
            <!--              <span class="text-body-2">{{ order.id }}</span>-->
            <!--            </p>-->
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col class="col-12 col-md-9">
            <v-expansion-panels v-model="panel" hover>
              <v-expansion-panel
                  v-for="(item, i) in menu"
                  :key="i"
                  class="mb-1"
                  :id="`category-` + i"
              >
                <v-expansion-panel-header class="font-weight-bold">
                  <h2>{{ item.name }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense class="mb-8">
                    <v-list-item v-for="(desc, x) in item.description" :key="x"
                    >{{ desc }}
                    </v-list-item>
                  </v-list>

                  <CardItem
                      v-for="food in item.foods"
                      :key="food.id"
                      class="mx-auth mt-5"
                      outlined
                      :food="food"
                      :count="countFromCard(food.id)"
                      @incCount="incCount"
                      @decCount="decCount"
                  ></CardItem>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col class="col-12 col-md-3">
            <v-row>
              <v-col class="col-12 categories d-none d-md-inline">
                <v-card>
                  <v-list>
                    <v-list-item class="font-weight-bold"
                    >Categories
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                        link
                        @click="$vuetify.goTo('#category-' + i)"
                        v-for="(item, i) in menu"
                        :key="i"
                    >
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  class="col-12 shopping-card"
                  id="checkout"
                  v-intersect="onIntersect"
              >
                <v-card
                    v-if="order.items.length > 0"
                    :elevation="checkoutElevation"
                >
                  <v-card-title>
                    <v-icon class="mr-5" x-large>mdi-basket</v-icon>
                    Checkout
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <ShoppingCardItem
                        v-for="food in order.items"
                        :key="food.id"
                        class="mx-auth mt-3"
                        :food="food"
                        :count="countFromCard(food.foodId)"
                        @incCount="incCount"
                        @decCount="decCount"
                    ></ShoppingCardItem>

                    <v-textarea
                        class="mt-5"
                        v-model="orderNote"
                        auto-grow
                        outlined
                        color="deep-purple"
                        label="Extra Note"
                        append-icon="mdi-pen"
                        rows="5"
                    ></v-textarea>

                    <v-card outlined class="mt-5">
                      <v-card-title>Summary</v-card-title>
                      <ShoppingCardItemSummary
                          v-for="food in order.items"
                          :key="food.id + 'summary'"
                          :food="food"
                          :count="countFromCard(food.foodId)"
                      ></ShoppingCardItemSummary>
                      <v-col cols="12" class="text-left grey--text">+ 6% tax applied.</v-col>
                      <v-divider class="my-4"></v-divider>
                      <v-row class="mb-2 mx-1">
                        <v-col class="col-8 text-left body-2">Total:</v-col>
                        <v-col class="col-4 body-2 text-right font-weight-bold"
                        >$ {{ order.totalPrice }}
                        </v-col>
                      </v-row>
                    </v-card>

                    <template v-if="!order.openTab">
                      <CloverPaymentBox :order="order" :note="orderNote"></CloverPaymentBox>
                    </template>
                    <template v-if="order.openTab">
                      <v-btn
                          block
                          class="px-5 mb-4"
                          color="green mt-5"
                          dark
                          elevation="0"
                          :loading="submitAndPayLoading"
                          @click="submitAndPay"
                      >Submit Order
                      </v-btn>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <!--      v-for="(index,item) in menu.items" :key="item.category"-->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardItem from "@/components/CardItem";
import ShoppingCardItem from "@/components/ShoppingCardItem";
import ShoppingCardItemSummary from "@/components/ShoppingCardItemSummary";
import axios from "axios";
import CloverPaymentBox from "@/components/CloverPaymentBox";
import router from "@/router";

export default {
  name: "Order",
  components: {CloverPaymentBox, ShoppingCardItemSummary, CardItem, ShoppingCardItem},
  computed: {},
  methods: {
    all() {
      this.panel = [...Array(this.menu.length).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },

    submitAndPay() {
      let cp = this;
      cp.submitAndPayLoading = true;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/submit`;
      axios
          .post(url, {
            note: cp.orderNote,
          })
          .then((response) => {
            if (response.status === 200) {
              cp.showSuccess("Order Placed!", "");

              router.push({
                name: "Factor",
                params: {
                  storeId: cp.storeId,
                  tableId: cp.tableId,
                  orderId: cp.order.id,
                },
              });
            } else {
              cp.showNetworkError(response);
            }
          })
          .catch((error) => {
            cp.showNetworkError(error);
          })
          .finally(() => {
            cp.submitAndPayLoading = false;
          });
    },
    showError(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "error";
    },

    showSuccess(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "success";
    },

    showNetworkError(error) {
      if (error.response) {
        if (error.response.status === 400) {
          this.showError("Oops!", error.response.data["msg"]);
        } else {
          this.showError("Oops!", error.message);
        }
      } else {
        this.showError("Oops!", error.message);
      }
    },

    glowCheckout() {
      setTimeout(() => (this.checkoutElevation = 5), 700);
      setTimeout(() => (this.checkoutElevation = 2), 2000);
    },

    countFromCard(foodId) {
      let count = this.order.items.find(function (el) {
        return foodId === el.foodId;
      });
      if (count === undefined) {
        return 0;
      }
      return count.count;
    },
    createNewOrder() {
      let cp = this;
      let tableId = cp.tableId;
      let storeId = cp.storeId;
      cp.creatingOrder = true;
      axios
          .post(process.env.VUE_APP_SERVER_API + "/user/orders/", {
            storeId: storeId,
            tableId: tableId,
            uuid: "-",
          })
          .then((response) => {
            cp.order = response.data;
            cp.creatingOrder = false;
            this.fetchMenu();
            this.fetchCard();
          })
          .catch((error) => {
            cp.showNetworkError(error);
          });
    },

    fetchCard() {
      let cp = this;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/card`;
      axios.get(url).then((response) => {
        cp.order = response.data;
      });
    },

    updateCardItem(foodId, count) {
      let cp = this;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/card`;
      axios
          .post(url, {
            foodId: foodId,
            count: count,
          })
          .then((response) => {
            cp.order = response.data;
          });
    },
    fetchMenu() {
      let cp = this;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/menu`;
      axios.get(url).then((response) => {
        cp.menu = response.data;
        // cp.all();
        cp.none();
        // let food = this.foodById("61498eb228fd6a166d3e242e");
        // food.count = 5;
        // let food2 = this.foodById("61498eb228fd6a166d3e242e");
        // console.log("food updated -> " + JSON.stringify(food2));
      });
    },

    callForServer() {
      let cp = this;
      cp.call.loading = true;
      let orderId = cp.order.id;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${orderId}/call/server`;
      axios
          .post(url, {
            orderId: cp.order.id,
            text: cp.call.note,
          })
          .then((response) => {
            cp.call.show = false;
            if (response.status === 200) {
              cp.showSuccess("OK! ", "Server will be there within a minutes.");
            } else {
              cp.showNetworkError(response);
            }
          })
          .catch((error) => {
            cp.showNetworkError(error);
          })
          .finally(() => {
            cp.call.loading = false;
          });
    },

    sendGooglePaymentToServer(paymentData) {
      console.log("payment data are -> ", paymentData);
      // let cp = this;
      // cp.call.loading = true;
      // let orderId = cp.order.id;
      // let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${orderId}/call/server`;
      // axios
      //     .post(url, {
      //       orderId: cp.order.id,
      //       text: cp.call.note,
      //     })
      //     .then((response) => {
      //       cp.call.show = false;
      //       if (response.status === 200) {
      //         cp.showSuccess("OK! ", "Server will be there within a minutes.");
      //       } else {
      //         cp.showNetworkError(response);
      //       }
      //     })
      //     .catch((error) => {
      //       cp.showNetworkError(error);
      //     })
      //     .finally(() => {
      //       cp.call.loading = false;
      //     });
    },

    onLoadPaymentData(event) {
      console.log("load payment data", event.detail);
      this.sendGooglePaymentToServer(event.detail);
    },

    onPaymentError: (event) => {
      console.error("error", event.error);
    },

    foodById(foodId) {
      // console.log("get food by id : " + foodId);
      console.log(this.menu);
      let food;
      this.menu.forEach((item) => {
        console.log(item);
        if (!food) {
          food = item.foods.find((x) => x.id === foodId);
        }
      });
      // console.log("get food by id result: " + JSON.stringify(food));
      return food;
    },

    incCount(foodId) {
      console.log("inc food id count by 1 " + foodId);
      let count = this.countFromCard(foodId);
      count += 1;
      this.updateCardItem(foodId, count);
    },
    decCount(foodId) {
      console.log("dec food id count by 1 " + foodId);
      let count = this.countFromCard(foodId);
      if (count <= 1) {
        count = 0;
      } else {
        count -= 1;
      }
      this.updateCardItem(foodId, count);
    },

    onIntersect(entries, observer) {
      console.log(observer);
      this.isIntersecting = entries[0].isIntersecting;
    },
  },
  beforeMount() {
    this.storeId = this.$route.params.storeId;
    this.tableId = this.$route.params.tableId;
    this.createNewOrder();
  },

  data: () => ({
    creatingOrder: true,
    storeId: "",
    tableId: "",
    submitAndPayLoading: false,
    order: {
      id: "",
      tableId: "",
      tableName: "-",
      storeName: "-",
      items: [],
      extraPrice: {},
      totalPrice: 0.0,
      paymentId: null,
      note: null,
      status: "ADD_TO_CARD",
      created: 1622042007887,
      modified: 1622042007887,
      openTab: false,
    },
    orderNote: "",
    checkoutElevation: 2,
    panel: [],
    menu: [
      {
        description: [""],
        foods: [
          {
            id: "",
            categoryId: "",
            name: "",
            price: 0,
            images: [],
            contents: [""],
            hide: false,
            soldOut: false,
          },
        ],
        id: "",
        name: "",
      },
    ],
    isIntersecting: false,
    paymentBoxVisible: true,
    card: {
      number: "",
      exp_month: "",
      exp_year: "",
      cvv: "",
    },
    snackbar: {
      show: false,
      color: "error",
      msg: "",
      details: "",
    },
    call: {
      disable: false,
      loading: false,
      show: false,
      note: "",
    },
  }),
};
</script>

