<template>
  <v-card class="my-2" outlined>
    <v-card-title class="text-body-1">{{ count }} x {{ food.name }}</v-card-title>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col>
          <h3 class="text-left">$ {{ food.totalPrice }}</h3>
        </v-col>
        <v-col class="col-auto">
          <v-btn rounded small outlined color="grey" dark elevation="0" @click="decCount">-</v-btn>
          <v-chip color="white" class="mx-1 px-4 py-2 font-weight-bold">{{ food.count }}</v-chip>
          <v-btn rounded small class="font-weight-bold" color="black" dark elevation="0" @click="incCount">+</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  name: "ShoppingCardItem",
  methods: {
    incCount() {
      this.$emit("incCount", this.food.foodId);
    },
    decCount() {
      this.$emit("decCount", this.food.foodId);
    },
  },
  props: {
    food: Object,
    count: Number,
  },
}
</script>
