<template>
  <v-container fill-height fluid>
    <v-snackbar
        multi-line
        v-model="snackbar.show"
        timeout="3200"
        :color="snackbar.color"
    >
      <b> {{ snackbar.msg }}</b>
      {{ snackbar.details }}
    </v-snackbar>

    <v-row v-if="loading" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-progress-circular
            :size="70"
            :width="7"
            color="error"
            indeterminate
        ></v-progress-circular>
        <br/>
        <p class="text-h6 my-7">Submit Order..</p>
      </v-col>
    </v-row>


    <v-row v-if="!loading" align="center" justify="center">
      <v-col cols="12" sm="12" md="3" class="text-center">
        <v-card class="py-6 px-10">


          <v-row>

            <v-col cols="12">
              <v-icon size="128" class="light-green--text mt-10">mdi-check</v-icon>
              <p class="text-h4 mt-7 font-weight-bold">Order Placed!</p>
            </v-col>
            <v-col cols="12" class="my-6">
              <p class="text-body-1 grey--text text-left">
                Enter your email to get the factor in your inbox:
              </p>
              <v-text-field
                  v-model="email.address"
                  outlined
                  required
                  :loading="email.loading"
                  append-icon="mdi-email"
                  hint="example@foo.com"
                  label="Email"
              ></v-text-field>

              <v-btn
                  :loading="email.loading"
                  :disabled="email.disabled"
                  block
                  class="primary"
                  @click="emailFactorPDF">
                <v-icon class="mr-6">mdi-email</v-icon>
                Send To My Email Now
              </v-btn>
            </v-col>
            <v-col cols="12">
              <p class="grey--text">OR</p>
              <v-btn block class="success" @click="downloadFactorPDF">
                <v-icon class="mr-6">mdi-download</v-icon>
                Download To My Device
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col cols="12" class="text-left">
              <v-btn
                  block
                  outlined
                  @click="createNewOrder">
                <v-icon class="mr-6">mdi-arrow-left</v-icon>
                Place New Order
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "Factor",
  computed: {},
  methods: {
    showError(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "error";
    },

    showSuccess(msg, details) {
      this.snackbar.msg = msg;
      this.snackbar.details = details;
      this.snackbar.show = true;
      this.snackbar.color = "success";
    },

    showNetworkError(error) {
      if (error.response) {
        if (error.response.status === 400) {
          this.showError("Oops!", error.response.data["msg"]);
        } else {
          this.showError("Oops!", error.message);
        }
      } else {
        this.showError("Oops!", error.message);
      }
    },

    downloadFactorPDF() {
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/factor/download`;
      window.open(url, "_blank");
    },

    emailFactorPDF() {
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/factor/email`;
      let cp = this;
      cp.email.loading = true;

      axios
          .post(url, {email: cp.email.address})
          .then((response) => {
            cp.email.disabled = true;
            cp.email.loading = false;
            cp.showSuccess(response.data.msg)

          }).catch((error) => {
        cp.email.loading = false;

        cp.showNetworkError(error);
      });
    },
    processPayment: (paymentData) => {
      console.log("processPayment called");
      console.log("processPayment payment data", paymentData);
      // show returned data in developer console for debugging
      console.log(paymentData);

      this.paymentToken = paymentData.paymentMethodData.tokenizationData.token;
      console.log("payment paymentToken ", this.paymentToken);
    },
    onLoadPaymentData: (event) => {
      console.log("load payment data", event.detail);
    },

    onPaymentError: (event) => {
      console.error("error", event.error);
    },

    onPaymentDataAuthorized: (paymentData) => {
      console.log("payment authorized", paymentData);
      return {
        transactionState: "SUCCESS",
      };
    },
    onReadyToPayChange: (event) => {
      console.log("ready to pay change", event.detail);
    },
    onClickPreventDefault: (event) => {
      console.log("prevent default");
      event.preventDefault();
    },

    fetchCard() {
      let cp = this;
      let url = `${process.env.VUE_APP_SERVER_API}/user/orders/${this.order.id}/card`;
      cp.loading = true;
      axios
          .get(url)
          .then((response) => {
            cp.order = response.data;
            cp.loading = false;
          })
          .catch((error) => {
            cp.showNetworkError(error);
          });
    },

    createNewOrder() {
      let cp = this;
      router.push({
        name: "Order",
        params: {
          storeId: cp.storeId,
          tableId: cp.tableId,
        },
      });
    }

  },
  beforeMount() {
    this.storeId = this.$route.params.storeId;
    this.tableId = this.$route.params.tableId;
    this.order.id = this.$route.params.orderId;
    this.fetchCard();
  },
  mounted() {
  },

  data: () => ({
    email: {
      address: "",
      loading: false,
      disabled: false,
    },

    loading: true,
    storeId: "",
    tableId: "",
    paymentToken: "",
    order: {
      id: "",
      tableId: "",
      tableName: "-",
      storeName: "-",
      items: [],
      extraPrice: {},
      totalPrice: 0.0,
      paymentId: null,
      note: null,
      status: "ADD_TO_CARD",
      created: 1622042007887,
      modified: 1622042007887,
      openTab:false
    },
    snackbar: {
      show: false,
      color: "error",
      msg: "",
      details: "",
    },
    googlePayConfigs: "--------------------------",

    existingPaymentMethodRequired: false,
    buttonColor: "default",
    buttonType: "pay",
    paymentRequest: {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["MASTERCARD", "VISA"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "example",
              gatewayMerchantId: "exampleGatewayMerchantId",
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: "12345678901234567890",
        merchantName: "Demo Merchant",
      },
    },
  }),
};
</script>
